/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import AddClientModal from '../../components/modals/AddClientModal';
import { getDateFormat } from '../../helpers/utils';

const ClientPage = () => {

    const snap = useSnapshot(store);
    const [fetch,setFetch] = useState(false);

    const bread = {
        title: "Clients",
        subtitle: "Liste des clients"
    }

    const fetchClients = async () => {
        await store.user.getClients();
        if (snap.user.clients.length > 0) {
            // console.log("clients", snap.user.clients);
            // setFetch(true);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchClients();
            setFetch(true);
        }
        if (fetch === false) {
            fetchData();
        }
        return () => {
        }
    },[]);

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES CLIENTS</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-search">
                                        <i className="ti ti-search" />
                                        <input type="search" className="form-control" placeholder="Search Followers" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <AddClientModal />
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Nom</th>
                                            <th>Prénoms</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Pays</th>
                                            <th>Adresse</th>
                                            {/* <th>Genre</th> */}
                                            <th>Date création</th>
                                            {/* <th>Actions</th> */}
                                            {/* Ajoutez d'autres colonnes en fonction des propriétés de vos clients */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.user.clients.map((client) => (
                                            <tr key={client.id}>
                                                <td>{client.id}</td>
                                                <td>{client.nom}</td>
                                                <td>{client.prenoms}</td>
                                                <td>{client.email}</td>
                                                <td>{client.phone}</td>
                                                <td>{client.country?.name}</td>
                                                <td>{client.address}</td>
                                                {/* <td>{client.gender}</td> */}
                                                <td>{getDateFormat(client.created_at)}</td>
                                                <td>
                                                    {/* <Link to={`/dashboard/clients/${client.id}`} className='btn btn-sm btn-primary'>
                                                        <i className="ti ti-eye"></i>
                                                    </Link> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientPage
