import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/layouts/BreadCrumb';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { Link } from 'react-router-dom';
import { getDateFormat } from '../../helpers/utils';
import { toast } from 'react-toastify';

const PostPage = () => {
    const bread = {
        title: "Actualités",
        subtitle: "Liste des actualités"
    };

    const snap = useSnapshot(store);
    const [fetch, setFetch] = useState(false);

    const fetchPosts = async () => {
        await store.core.getPosts();
    };

    const get_status_format = (status: string) => {
        if (status === 'published') {
            return 'Publiée';
        } else if (status === 'draft') {
            return 'Brouillon';
        } else if (status === 'pending') {
            return 'En attente';
        } else {
            return 'Inconnu';
        }
    };

    const handleDelete = async(id: number) => {
        if (window.confirm("Voulez-vous vraiment supprimer cette actualité ?")) {
            const res = await store.core.deletePost(id);
            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
            fetchPosts();
        }
    };

    const handleStatusChange = async (postId: string, newStatus: string) => {
        if (window.confirm(`Voulez-vous vraiment passer cette actualité en ${get_status_format(newStatus)} ?`)) {
            const res = await store.core.updatePostStatus({
                uuid: postId,
                status: newStatus
            });
            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
            fetchPosts();
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchPosts();
            setFetch(true);
        };
        if (fetch === false) {
            fetchData();
        }
        return () => {
        };
    }, []);

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES ACTUALITES</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-search">
                                        <i className="ti ti-search" />
                                        <input type="search" className="form-control" placeholder="Rechercher une actualité" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Link to={`/dashboard/posts/create`} className='btn btn-md btn-primary'>
                                        <i className="ti ti-add"></i>Nouvelle actualité
                                    </Link>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Titre</th>
                                            <th>Catégorie</th>
                                            {/* <th>Tags</th> */}
                                            <th>Type</th>
                                            <th>Bannière</th>
                                            {/* <th>Video</th> */}
                                            {/* <th>Images</th> */}
                                            <th>Status</th>
                                            <th>Création</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.posts && Array.isArray(snap.core.posts) ? (
                                            snap.core.posts.map((post,index) => (
                                                <tr key={index}>
                                                    <td>{index+1}</td>
                                                    <td>{post.title}</td>
                                                    <td>{post.category?.name}</td>
                                                    {/* <td>{post.tags}</td> */}
                                                    <td>{post.type}</td>
                                                    <td>
                                                        <img src={post.img_banner} alt={post.title} width={100} />
                                                    </td>
                                                    {/* <td>{post.videoUrl}</td> */}
                                                    {/* <td>{post.images?.length}</td> */}
                                                    <td style={{width: '100%'}} >
                                                        {/* {get_status_format(post.status)} */}

                                                        <select
                                                            value={post.status}
                                                            onChange={(e) => handleStatusChange(post.uuid, e.target.value)}
                                                            className="form-select form-select-sm"
                                                        >
                                                            <option value="published">Publiée</option>
                                                            <option value="draft">Brouillon</option>
                                                            <option value="pending">En attente</option>
                                                        </select>
                                                    </td>
                                                    <td>{getDateFormat(post.created_at)}</td>
                                                    <td>
                                                        <Link to={`/dashboard/posts/details/${post.id}`} className='btn btn-sm btn-primary'>
                                                            <i className="ti ti-eye"></i>
                                                        </Link>
                                                        <Link to={`/dashboard/posts/edit/${post.uuid}`} className='btn btn-sm btn-warning'>
                                                            <i className="ti ti-edit"></i>
                                                        </Link>
                                                        <button className='btn btn-sm btn-danger' onClick={() => handleDelete(post.id)}>
                                                            <i className="ti ti-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={12} className='text-center' >Aucune actualité trouvée</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostPage;
