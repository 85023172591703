import React from 'react'
import { BrowserRouter as Router, Routes,Route, Navigate,  } from 'react-router-dom';
import MainLayout from './components/layouts/MainLayout';
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import AnalyticPage from './pages/dashboard/AnalyticPage';
import UsersPage from './pages/users/UsersPage';
import ActivityPage from './pages/users/ActivityPage';
import ClientPage from './pages/clients/ClientPage';
import ContactPage from './pages/clients/ContactPage';
import PlanPage from './pages/plans/PlanPage';
import ServicesPage from './pages/services/ServicesPage';
import SubscriptionPage from './pages/subscriptions/SubscriptionPage';
import PaymentPage from './pages/payments/PaymentPage';
import CategoryPage from './pages/blog/CategoryPage';
import PostPage from './pages/blog/PostPage';
import DevisPage from './pages/services/DevisPage';
import { checkAuthUser } from './helpers/utils';
import DetailClientPage from './pages/clients/DetailClientPage';
import AddNewPostPage from './pages/blog/AddNewPostPage';
import DetailPostPage from './pages/blog/DetailPostPage';
import EditPostPage from './pages/blog/EditPostPage';

const ProtectedRoute = ({ element, ...rest }: { element: any }) => {
    const isAuthenticated = checkAuthUser(); // Vérifie si l'utilisateur est authentifié
    return isAuthenticated ? element : <Navigate to="/" replace />;
};


const AppRoutes = () => {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<ProtectedRoute element={<MainLayout />} />} >
                        <Route index element={<Dashboard />} />
                        <Route path="analytics" element={<AnalyticPage />} />
                        <Route path="users" element={<UsersPage />} />
                        <Route path="users-activities" element={<ActivityPage />} />
                        <Route path="clients" element={<ClientPage />} />
                        <Route path="clients/:client_id" element={<DetailClientPage />} />
                        <Route path="contacts" element={<ContactPage />} />
                        <Route path="plans" element={<PlanPage />} />
                        <Route path="services" element={<ServicesPage />} />
                        <Route path="devis" element={<DevisPage />} />
                        <Route path="subscriptions" element={<SubscriptionPage />} />
                        <Route path="payments" element={<PaymentPage />} />
                        <Route path="categories" element={<CategoryPage />} />
                        <Route path="posts" element={<PostPage />} />
                        <Route path="posts/create" element={<AddNewPostPage />} />
                        <Route path='posts/details/:uuid' element={<DetailPostPage />} />
                        <Route path='posts/edit/:uuid' element={<EditPostPage />} />
                    </Route>
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default AppRoutes
