import React, { useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';

interface EditorProps {
    onContentChange?: (content: string) => void;
    content?: string; // Le contenu est une chaîne HTML
}

const Editor: React.FC<EditorProps> = ({ onContentChange, content }) => {
    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }, { direction: 'ltr' }], // text direction
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ['clean'], // remove formatting button
    ];

    const { quill, quillRef } = useQuill({
        modules: {
            toolbar: toolbarOptions,
            clipboard: {
                matchVisual: false, // Prevents extra line breaks
            },
        },
        formats: [
            'header',
            'font',
            'size',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'indent',
            'link',
            'image',
            'video',
            'direction', // Ajout pour gérer la direction du texte
        ],
        theme: 'snow',
    });

    useEffect(() => {
        if (quill) {
            if (content) {
                quill.clipboard.dangerouslyPasteHTML(content);
                const length = quill.getLength(); // Obtenir la longueur du contenu
                quill.setSelection(length - 1, 0); // Positionner le curseur à la fin
            }

            const handleTextChange = () => {
                if (onContentChange) {
                    onContentChange(quill.root.innerHTML);
                }
            };

            quill.on('text-change', handleTextChange);

            return () => {
                quill.off('text-change', handleTextChange);
            };
        }
    }, [quill, onContentChange]);

    useEffect(() => {
        if (quill && content) {
            quill.clipboard.dangerouslyPasteHTML(content);
            const length = quill.getLength(); // Obtenir la longueur du contenu
            quill.setSelection(length - 1, 0); // Positionner le curseur à la fin
            quill.format('direction', 'ltr'); // S'assurer que la direction est gauche à droite
        }
    }, [content, quill]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div ref={quillRef} />
        </div>
    );
};

export default Editor;
