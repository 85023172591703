/* eslint-disable @typescript-eslint/no-unused-vars */
import { proxy } from 'valtio';
import type { Response, Plan, Service, Devis, Subscription, Transaction, Country, Client,Category,Tag,Post } from '../../models';
import { getDataWithToken,getData, postDataWithFile, postDataWithToken } from '../../helpers/http';

class CoreStore {
    plans: Plan[] = [];
    services: Service[] = [];
    devis: Devis[] = [];
    subscriptions: Subscription[] = [];
    transactions: Transaction[] = [];
    countries: Country[] = [];
    clients: Client[] = [];
    categories: Category[] = [];
    tags: Tag[] = [];
    posts: Post[] = [];
    


    async getPlans(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/plans');
            if (response.success) {
                this.plans = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getCountries(): Promise<Response> {
        try {
            const response = await getDataWithToken('countries');
            if (response.success) {
                this.countries = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    getCountryById(id: number) {
        return this.countries.find((country) => country.id === id);
    }

    async getServices(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/services');
            if (response.success) {
                this.services = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getDevis(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/devis');
            if (response.success) {
                this.devis = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async processedDevis(payload: any): Promise<Response> {
        try {
            const response = await postDataWithFile('admin/devis/processed', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getSubscriptions(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/subscriptions');
            console.log('============api response========================');
            console.log(response.result);
            console.log('====================================');
            if (response.success) {
                this.subscriptions = response.result;
            }
            return response;
        } catch (error) {
            console.log('============error========================');
            console.log(error);
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getTransactions(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/transactions');
            if (response.success) {
                this.transactions = response.result;
            }
            return response;
        } catch (error) {
            console.log('============error========================');
            console.log(error);
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getAnalyticData(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin');
            if (response.success) {
                this.transactions = response.result.transactions;
                this.clients = response.result.client;
                this.devis = response.result.devis;
                this.subscriptions = response.result.subscriptions;
            }
            return response;
        } catch (error) {
            console.log('============error========================');
            console.log(error);
            return {
                success: false,
                message: "Echec, une erreur interne est survenue",
                result: null,
            }
        }
    }

    async getCategories(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/blog/categories');
            if (response.success) {
                this.categories = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getTags(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/blog/tags');
            if (response.success) {
                this.tags = response.result;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getPosts(): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/blog/posts');
            if (response.success) {
                this.posts = response.result.data;
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async addCategory(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/categories/add', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async updateCategory(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/categories/update', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async deleteCategory(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/categories/delete', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async addTag(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/tags/add', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async updateTag(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/tags/update', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async addPost(payload: any): Promise<Response> {
        try {
            const response = await postDataWithFile('admin/blog/posts/create', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async updatePost(payload: any): Promise<Response> {
        try {
            const response = await postDataWithFile('admin/blog/posts/update', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async deletePost(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/posts/delete', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async updatePostStatus(payload: any): Promise<Response> {
        try {
            const response = await postDataWithToken('admin/blog/posts/change-status', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

    async getPostByUUID(uuid: string): Promise<Response> {
        try {
            const response = await getDataWithToken('admin/blog/posts/details/' + uuid);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };  
        }
    }

}

const coreStore = proxy(new CoreStore());

export default coreStore