import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import AddCategoryModal from '../../components/modals/AddCategoryModal';
import { Link } from 'react-router-dom';
import EditCategoryModal from '../../components/modals/EditCategoryModal';

const CategoryPage = () => {

    const bread = {
        title: "Actualités",
        subtitle: "Liste des catégories"
    }

    const snap = useSnapshot(store);
    const [fetch, setFetch] = useState(false);

    const fetchCategories = async () => {
        await store.core.getCategories();
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchCategories();
            setFetch(true);
        }
        if (fetch === false) {
            fetchData();
        }
        return () => {
        }
    }, []);

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Nos catégories d'actualités</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-4">
                                <div className="col">
                                    <div className="form-search">
                                        <i className="ti ti-search" />
                                        <input type="search" className="form-control" placeholder="Rechercher une catégorie" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <AddCategoryModal />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            {/* <th>ID</th> */}
                                            <th>Nom</th>
                                            <th>Description</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {snap.core.categories && Array.isArray(snap.core.categories) ? (
                                            snap.core.categories.map((category) => (
                                                <tr key={category.id}>
                                                    {/* <td>{category.id}</td> */}
                                                    <td>{category.name}</td>
                                                    <td>{category.description}</td>
                                                    <td>
                                                        <EditCategoryModal category={category} />
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={3}>Aucune catégorie trouvée</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    <tfoot>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item disabled">
                                                    <a className="page-link" href="#!" tabIndex={-1}>Previous</a>
                                                </li>
                                                <li className="page-item"><a className="page-link" href="#!">1</a></li>
                                                <li className="page-item"><a className="page-link" href="#!">2</a></li>
                                                <li className="page-item"><a className="page-link" href="#!">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#!">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryPage
