import React, { useEffect, useState } from 'react';
import BreadCrumb from '../../components/layouts/BreadCrumb';
import { SubmitHandler, useForm } from 'react-hook-form';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import Editor from '../../components/widgets/Editor';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type PostForm = {
    title: string;
    category_id: number;
    tags: object;
    type: 'article' | 'video' | 'gallery' | 'image' | 'link';
    img_banner: FileList;
    videoUrl: string | null;
    images: FileList | null;
    content: string;
    uuid: string;
    created_at: string;
    updated_at: string;
    published_at: string | null;
    status: string;
    metadata: {
        title: string;
        keyword: string;
        description: string;
    };
}

const AddNewPostPage = () => {
    const bread = {
        title: "Actualités",
        subtitle: "Ajouter une nouvelle actualité"
    }
    const navigate = useNavigate();
    const snap = useSnapshot(store);
    const [isLoaded, setIsLoaded] = useState(false);
    const [content, setContent] = useState('');
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm<PostForm>();

    useEffect(() => {
        const fetchData = async () => {
            await store.core.getCategories();
            await store.core.getTags();
            setIsLoaded(true);
        };
        fetchData();
    }, []);

    const onSubmit: SubmitHandler<PostForm> = async (data) => {
        console.log("data", data);
        let btn_addPost = document.getElementById('btn_addPost') as HTMLButtonElement;
        btn_addPost.disabled = true;
        btn_addPost.innerText = "Traitement en cours...";
        try {
            data.content = content;
            if (content === '') {
                toast.error("Le contenu est obligatoire");
                btn_addPost.disabled = false;
                btn_addPost.innerText = "Ajouter";
                return;
            }
            const formData = new FormData();
            for (const key in data) {
                const value = data[key as keyof typeof data];
                if (key === 'tags') {
                    // Ensure tags and images are arrays
                    if (Array.isArray(value)) {
                        value.forEach((item, index) => {
                            formData.append(`${key}[${index}]`, item);
                        });
                    }
                } 
                
                if(key === 'images' && value instanceof FileList) {
                    for (let i = 0; i < value.length; i++) {
                        formData.append('images[]', value[i]);
                    }
                }

                if(key === 'img_banner' && value instanceof FileList) {
                    formData.append(key, value[0]);
                }
                
                if (key === 'metadata' && typeof value === 'object') {
                    // Ensure metadata is an object
                    for (const subKey in value) {
                        const subValue = value[subKey as keyof typeof value];
                        formData.append(`metadata[${subKey}]`, subValue ?? '' as any);
                    }
                }
                if (!['metadata', 'tags', 'img_banner', 'images'].includes(key)) {
                    formData.append(key, value ?? '' as any);
                }
            }
    
            const res = await store.core.addPost(formData);
            // console.log("res", res);
            if (res.success) {
                toast.success(res.message);
                reset();
                setTimeout(() => {
                    navigate('/dashboard/posts');
                }, 1500);
            } else {
                toast.error(res.message);
                setTimeout(() => {
                    btn_addPost?.removeAttribute('disabled');
                }, 3500);
                btn_addPost.innerText = 'Ajouter';
            }
        } catch (error) {
            console.log("error occurred", error);
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_addPost?.removeAttribute('disabled');
            }, 3500);
            btn_addPost.innerText = 'Ajouter';
        }
    }
    
    const handleContentChange = (newContent: string) => {
        setContent(newContent);
        setValue('content', newContent);
        setTimeout(() => {
            console.log("content",content);
        }, 1000);
    }

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                            <div className="card-header">
                                <h5 className="card-title">Ajouter une nouvelle actualité</h5>
                            </div>
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="title">Titre</label>
                                    <input type="text" className="form-control" id="title" {...register("title", { required: true })} />
                                    {errors.title && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="category_id">Catégorie</label>
                                    <select className="form-control" id="category_id" {...register("category_id", { required: true })}>
                                        <option value="">Sélectionnez une catégorie</option>
                                        {isLoaded && snap.core.categories.map((category) => (
                                            <option key={category.id} value={category.id}>{category.name}</option>
                                        ))}
                                    </select>
                                    {errors.category_id && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="tags">Tags</label>
                                    <select className="form-control" id="tags" multiple {...register("tags", { required: false })}>
                                        {isLoaded && snap.core.tags.map((tag) => (
                                            <option key={tag.id} value={tag.id}>{tag.name}</option>
                                        ))}
                                    </select>
                                    {errors.tags && <span className="text-danger">Ce champ est requis</span>}
                                </div> */}
                                <div className="form-group">
                                    <label htmlFor="type">Type</label>
                                    <select className="form-control" id="type" {...register("type", { required: true })}>
                                        <option value="">Sélectionnez un type</option>
                                        <option value="article">Article</option>
                                        <option value="video">Vidéo</option>
                                        {/* <option value="gallery">Galerie</option>
                                        <option value="image">Image</option>
                                        <option value="link">Lien</option> */}
                                    </select>
                                    {errors.type && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="img_banner">Bannière</label>
                                    <input type="file" className="form-control" id="img_banner" {...register("img_banner", { required: true })} />
                                    {errors.img_banner && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="videoUrl">URL de la vidéo</label>
                                    <input type="text" className="form-control" id="videoUrl" {...register("videoUrl")} />
                                </div>
                                {/* <div className="form-group">
                                    <label htmlFor="images">Images</label>
                                    <input type="file" className="form-control" id="images" multiple {...register("images")} />
                                </div> */}

                                <div className="form-group">
                                    <label htmlFor="status">Statut</label>
                                    <select className="form-control" id="status" {...register("status", { required: true })}>
                                        <option value="">Sélectionnez un statut</option>
                                        <option value="draft">Brouillon</option>
                                        <option value="published">Publié</option>
                                        <option value="archived">Archivé</option>
                                    </select>
                                    {errors.status && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="metadataTitle">Titre des métadonnées</label>
                                    <input type="text" className="form-control" id="metadataTitle" {...register("metadata.title", { required: true })} />
                                    {errors.metadata?.title && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="metadataKeyword">Mot-clé des métadonnées</label>
                                    <input type="text" className="form-control" id="metadataKeyword" {...register("metadata.keyword", { required: false })} />
                                    {errors.metadata?.keyword && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="metadataDescription">Description des métadonnées</label>
                                    <textarea className="form-control" id="metadataDescription" {...register("metadata.description", { required: false })}></textarea>
                                    {errors.metadata?.description && <span className="text-danger">Ce champ est requis</span>}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="content">Contenu</label>
                                    {/* <textarea className="form-control" id="content" {...register("content", { required: true })}></textarea> */}
                                    <Editor onContentChange={handleContentChange} />
                                    {errors.content && <span className="text-danger">Ce champ est requis</span>}
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="submit" id='btn_addPost' className="btn btn-primary">Ajouter</button>
                                <button type="reset" className="btn btn-danger ms-2">Annuler</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewPostPage;
