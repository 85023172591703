import React from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'

const DetailPostPage = () => {

    const bread = {
        title: "Actualités",
        subtitle: "Liste des actualités"
    }

    return (
        <div>
            <BreadCrumb bread={bread} />
            <div className="row">
                <div className="col-md-12">

                </div>
            </div>
        </div>
    )
}

export default DetailPostPage