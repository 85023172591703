import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import store from '../../stores/store';
import { toast } from 'react-toastify';
import { Category } from '../../models';

type CategoryForm = {
    name: string;
    description: string | null;
}

interface EditCategoryModalProps {
    category: Category;
    onClose?: () => void;
}

const EditCategoryModal: React.FC<EditCategoryModalProps> = ({ category, onClose }) => {
    const { register, handleSubmit, reset,setValue } = useForm<CategoryForm>();

    useEffect(() => {
        // Pré-remplir le formulaire avec les données de la catégorie
        if (category) {
            setValue('name', category.name);
            setValue('description', category.description);
        }
    }, [category, reset]);

    const onSubmit: SubmitHandler<CategoryForm> = async (data) => {
        let btn_editCat = document.getElementById('btn_editCat') as HTMLButtonElement;
        btn_editCat.disabled = true;
        btn_editCat.innerText = "Traitement en cours...";
        try {
            const { updateCategory } = store.core;
            // Ajouter l'ID de la catégorie aux données
            const updateData = {
                ...data,
                id: category.id
            };
            
            const res = await updateCategory(updateData);

            if (res.success) {
                toast.success(res.message);
                // Fermer le modal
                if (onClose) {
                    onClose();
                }
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } else {
                toast.error(res.message);
                btn_editCat.disabled = false;
                btn_editCat.innerText = 'Modifier';
            }
        } catch (error) {
            console.log("error occurred", error);
            toast.error("Erreur de connexion à l'api");
            btn_editCat.disabled = false;
            btn_editCat.innerText = 'Modifier';
        }
    }

    const modalId = `editCategoryModal_${category.id}`;

    return (
        <div>
            <button 
                type="button" 
                className="btn btn-warning btn-sm" 
                data-bs-toggle="modal" 
                data-bs-target={`#${modalId}`}
            >
                Modifier
            </button>
            <div id={modalId} className="modal fade" tabIndex={-1} role="dialog" aria-labelledby={`${modalId}Title`} aria-hidden="true">
                <form onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id={`${modalId}Title`}>Modifier la catégorie: {category.name} </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="form-label">Nom</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Entrez le nom de la catégorie" 
                                        {...register("name")} 
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Description</label>
                                    <textarea 
                                        className="form-control" 
                                        placeholder="Entrez la description de la catégorie" 
                                        {...register("description")} 
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <button type="submit" className="btn btn-warning" id='btn_editCat'>Modifier</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditCategoryModal;
