import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import store from '../../stores/store';
import { toast } from 'react-toastify';

type CategoryForm = {
    name: string;
    description: string;
}

const AddCategoryModal = () => {
    const { register, handleSubmit, reset } = useForm<CategoryForm>();

    const onSubmit: SubmitHandler<CategoryForm> = async (data) => {
        console.log("data", data);
        let btn_addCat = document.getElementById('btn_addCat') as HTMLButtonElement;
        btn_addCat.disabled = true;
        btn_addCat.innerText = "Traitement en cours...";
        try {
            const { addCategory } = store.core;
            const res = await addCategory(data);
            console.log("api res", res);

            if (res.success) {
                toast.success(res.message);
                // Reset form
                reset();
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            } else {
                toast.error(res.message);
                setTimeout(() => {
                    btn_addCat?.removeAttribute('disabled');
                }, 3500);
                btn_addCat.innerText = 'Ajouter';
            }
        } catch (error) {
            console.log("error occurred", error);
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_addCat?.removeAttribute('disabled');
            }, 3500);
            btn_addCat.innerText = 'Ajouter';
        }
    }

    return (
        <div>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">Nouvelle catégorie</button>
            <div id="exampleModalCenter" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <form onSubmit={handleSubmit(onSubmit)} method="post">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Ajouter une nouvelle catégorie</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label className="form-label">Nom</label>
                                    <input type="text" className="form-control" placeholder="Entrez le nom de la catégorie" {...register("name")} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">Description</label>
                                    <textarea className="form-control" placeholder="Entrez la description de la catégorie" {...register("description")} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <button type="submit" className="btn btn-primary" id='btn_addCat'>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AddCategoryModal;
